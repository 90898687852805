import "./Choices.css";
import logo from "../../logo.svg";

function Choice({ title, options, onClick, value }) {
  const number = title.charAt(0);
  return (
    <div className="choice" id={`choice-${number}`}>
      <p className="option-title">{title}</p>
      {options.map((o) => (
        <button
          key={o}
          className={o === value ? "active" : ""}
          onClick={() => onClick(o, number)}
        >
          {o}
        </button>
      ))}
    </div>
  );
}

function Choices({ formData, setFormData, goToNext }) {
  const onChoice = (key) => (value, number) => {
    document.getElementById(`choice-${Number(number) + 1}`)?.scrollIntoView({
      behavior: "smooth",
    });
    setFormData((data) => ({ ...data, [key]: value }));
  };

  return (
    <div className="choices-wrapper">
      <div className="row">
        <img className="logo" src={logo} alt="Artlist logo" />
        <p className="title row">What’s your developer personality type?</p>
        <div className="form-wrapper row">
          <Choice
            title="1. OOP or functional programming?"
            options={["OOP", "Functional Programming"]}
            onClick={onChoice("choices1")}
            value={formData.choices1}
          />
          <Choice
            title="2. How much time do you spend on Stackoverflow each day?"
            options={[
              "What is Stackoverflow?",
              "Up to an hour",
              "1 - 3 hours",
              "It’s always open",
            ]}
            onClick={onChoice("choices2")}
            value={formData.choices2}
          />
          <Choice
            title="3. Tabs or spaces?"
            options={["Tabs", "Spaces"]}
            onClick={onChoice("choices3")}
            value={formData.choices3}
          />
          <Choice
            title="4. Development philosophy?"
            options={["TDD", "BDD", "Leave me alone"]}
            onClick={onChoice("choices4")}
            value={formData.choices4}
          />
          <Choice
            title="5. What is your preferred way of learning new things?"
            options={[
              "Online courses",
              "Documentation",
              "Articles",
              "Dive into the code",
            ]}
            onClick={onChoice("choices5")}
            value={formData.choices5}
          />
          <input
            type="submit"
            className="submit"
            onClick={goToNext}
            disabled={
              !formData.choices1 ||
              !formData.choices2 ||
              !formData.choices3 ||
              !formData.choices4 ||
              !formData.choices5
            }
            value="Submit"
          />
        </div>
      </div>
      <div className="blur tablet-show top" />
      <div className="blur tablet-show bottom" />
    </div>
  );
}

export default Choices;
