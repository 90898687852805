import "./Home.css";
import logo from "../../logo.svg";
import trusted from "./trusted.png";
import albums from "./albums.png";

function Home({ formData, setFormData, goToNext }) {
  const handleChange = (key) => (e) =>
    setFormData((data) => ({ ...data, [key]: e.target.value }));

  return (
    <div className="home-wrapper">
      <header className="head row">
        <img className="logo" src={logo} alt="Artlist logo" />
        <p className="text">
          Hey! We are Artlist, a creative tech company empowering people to
          create without limits
        </p>
      </header>
      <div className="form-wrapper row">
        <div className="blur only-mobile" />
        <div className="form-inner-wrapper">
          <p className="form-title">
            Want to find out what kind of developer you are?
          </p>
          <p className="form-subtitle">
            Fill in your details to take the quiz.
          </p>
          <form className="form" onSubmit={goToNext}>
            <input
              value={formData.name}
              onChange={handleChange("name")}
              placeholder="Full name"
              type="text"
              required
            />
            <input
              value={formData.job}
              onChange={handleChange("job")}
              placeholder="Job title"
              type="text"
            />
            <input
              value={formData.email}
              onChange={handleChange("email")}
              placeholder="Email"
              type="email"
              required
            />
            <input
              value={formData.phone}
              onChange={handleChange("phone")}
              placeholder="Phone number"
              type="tel"
              required
            />
            <p className="terms">
              By submitting this form I allow Artlist to use my details
            </p>
            <input className="submit" type="submit" value="Take the Quiz" />
          </form>
        </div>
      </div>
      <div className="bottom row">
        <p className="trusted-title">Trusted by</p>
        <img
          className="trusted-img"
          src={trusted}
          alt="trusted companies logos"
        />
        <img className="albums" src={albums} alt="album cover images" />
      </div>
      <div className="blur tablet-show left" />
      <div className="blur tablet-show bottom" />
    </div>
  );
}

export default Home;
