import { useEffect } from "react";
import logo from "../../logo.svg";
import "./Result.css";

function Results({ formData }) {
  let type = "tenx";
  let title = "10X developer";
  let videoUrl = "https://d1mxxx05w0a4cp.cloudfront.net/geektime/10X.mp4";
  if (formData.choices2 === "It’s always open") {
    type = "copy";
    title = "Copy/paste developer";
    videoUrl = "https://d1mxxx05w0a4cp.cloudfront.net/geektime/Copy Paste.mp4";
  } else if (formData.choices1 === "Functional Programming") {
    type = "ninja";
    title = "Ninja developer";
    videoUrl = "https://d1mxxx05w0a4cp.cloudfront.net/geektime/Ninja.mp4";
  }

  useEffect(() => {
    const sendForm = async () => {
      if (!formData.name || !formData.email) {
        return;
      }
      const text = `
      Name: ${formData.name}
      Email: ${formData.email}
      Phone: ${formData.phone}
      Job: ${formData.job}
      Type: ${type}
      Time: ${new Date().toLocaleTimeString()}
      ------------------------------
      `;
      const body = JSON.stringify({ text });
      await fetch(
        "https://hooks.slack.com/services/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",
        {
          method: "POST",
          headers: {
            "Content-Length": body.length,
            Accept: "application/json",
          },
          body,
        }
      );
    };
    sendForm();
  }, []);

  return (
    <div className={`results-wrapper ${type}`}>
      <div className="row">
        <img className="logo" src={logo} alt="Artlist logo" />
        <div className="main row">
          <p className="title">{formData.name}, you are a:</p>
          <p className={`dev-title ${type}`}>{title}</p>
          <video src={videoUrl} autoPlay controls />
          <a
            className="submit"
            href="https://www.artlistjobs.io/?utm_source=rnd_survey&utm_medium=button&utm_campaign=rnd_survey"
          >
            Apply now
          </a>
        </div>
      </div>
    </div>
  );
}

export default Results;
