import { useEffect, useState } from "react";
import Home from "./pages/home";
import Choices from "./pages/choices";
import Results from "./pages/result";

import "./App.css";
console.log("update");

function App() {
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    job: "",
    email: "",
    phone: "",
    choice1: null,
    choice2: null,
    choice3: null,
    choice4: null,
    choice5: null,
  });

  useEffect(() => window.scrollTo(0, 0), [page]);

  if (page === 1)
    return (
      <Home
        formData={formData}
        setFormData={setFormData}
        goToNext={() => setPage(2)}
      />
    );
  if (page === 2) {
    return (
      <Choices
        formData={formData}
        setFormData={setFormData}
        goToNext={() => setPage(3)}
      />
    );
  }
  if (page === 3) return <Results formData={formData} />;
  return <div>404</div>;
}

export default App;
